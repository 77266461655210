<template>
  <div class="ziyun" ref="ddd">
    <!-- 头部 logo -->
    <header>
      <div class="h_center">
        <div class="h_logo">
          <img src="../assets/index/logo.png" alt />
          <div class="logo_title">
            <p class="logo_one">朝阳云教研</p>
            <p class="logo_two">—INTEGRATION—</p>
          </div>
          <div class="logo_subtitle">
            <p>如有疑问请联系平台管理人员</p>
            <p>
              <i class="el-icon-phone" style="font-size: 16px"></i>
              85858383-8
            </p>
            <p>工作时间：工作日 上午8:30-12：00 下午13:30-17:30</p>
          </div>
        </div>
        <!-- <div class="h_withe"></div> -->
        <el-input placeholder="搜索" v-model="search">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="h_login">
          <a :href="hrefss" v-if="this.username == null">登录</a>
          <div class="user_img" v-else>
            <img src="../assets/components/people.png" alt />
          </div>
          <p class="loginicon" v-if="this.username !== null">
            <span>{{ username }}</span>
            <a :href="outhrefss">
              <img src="../assets/icon/out.png" alt />
            </a>
          </p>
          <!-- 消息通知 -->
          <!-- <el-dropdown>
            <el-badge :value="this.noticeNum" class="badge" v-if="this.noticeNum!==null">
              <i></i>
            </el-badge>
            <el-badge class="badge" v-else>
              <i></i>
            </el-badge>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>系统消息</el-dropdown-item>
              <el-dropdown-item>通知消息</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <!-- -->
          <p class="login_other" @click="doOther" v-if="showdo">进入管理后台</p>
        </div>
      </div>
    </header>
    <!-- 导航 -->
    <nav>
      <el-menu
        :default-active="nowId + ''"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#1977f9"
        text-color="#ffffff"
        active-text-color="#fff"
      >
        <el-menu-item :index="item.numIndex" v-for="item in homeList" :key="item.id">{{ item.name }}</el-menu-item>
      </el-menu>
    </nav>
    <!-- 路由在此 -->
    <main>
      <router-view />
    </main>

    <!-- 底部 脚 -->
    <footer>
      <div class="footer_content">
        <!-- <h4>我是地址</h4> -->
      </div>
    </footer>
  </div>
</template>
<script>
import { homenav as navList } from "../static/index/indexnav.js"; //导航栏
export default {
  data() {
    return {
      search: null, //搜索框
      nowId: null, //当前激活的导航id
      hrefss: null, //登录跳转的地址
      outhrefss: null, //退出登录的地址
      // 用户名字
      username: null,
      // 消息数字
      noticeNum: 10,
      // 是否进入管后台
      showdo: 0,
      CheckId: null,
      CheckIds: null,
    };
  },
  created() {
    this.nowId = this.$route.meta.curId;
    this.homeList = navList;
    this.loginsys(); //获取登录的地址
    this.loginout(); //获取退出地址
    sessionStorage.clear(); //清空sessionStorage中所有信息
    this.CheckId = this.$cookies.get("JSESSIONID");
    // sessionStorage.setItem("currentUserName", '管理员');

    // console.log(this.CheckId, "CheckId");
    this.CheckIds = this.$cookies.keys();
    // console.log(this.CheckIds, "获取所有cookies");
    // if (this.CheckId !== null) {
    this.renzheng().then(() => {
      this.getUserMsg();
    });
    // }
  },
  computed: {},
  methods: {
    //
    async renzheng() {
      await this.$axios.get("./oauth").then((res) => {
        console.log(res, "认证");
      });
    },
    // 获取用户信息
    getUserMsg() {
      this.$Http.getUserMsg().then((res) => {
        // console.log(res, "登录用户信息");
        if (res.status == 200) {
          if (res.data.userName == null || res.data.userName == "" || res.data.userName == undefined) {
            this.$cookies.remove("JSESSIONID");
            // const CheckId1 = this.$cookies.get("JSESSIONID");
            // console.log(CheckId1, "CheckId1111");
            // sessionStorage.removeItem("key");//删除名称为“key”的信息。
            sessionStorage.clear(); //清空sessionStorage中所有信息
          } else {
            this.username = res.data.userName;
            this.showdo = res.data.enterBackageFlag;
            sessionStorage.setItem("currentUserName", res.data.userName);
          }
        } else if (res.status == 401) {
          this.$alert(res.message, "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              // console.log(this.outhrefss, "this.outhrefss", "退出登录");
              window.location = this.outhrefss;
              this.$cookies.remove("JSESSIONID");
              sessionStorage.clear(); //清空sessionStorage中所有信息
            },
          });
          return;
          setTimeout(() => {
            console.log(this.outhrefss, "this.outhrefss", "退出登录");
            window.location = this.outhrefss;
            this.$cookies.remove("JSESSIONID");
            sessionStorage.clear(); //清空sessionStorage中所有信息
          }, 2000);
        }
      });
    },
    // 登录
    loginsys() {
      this.$Http.getloginUrl().then((res) => {
        // console.log(res, "登录");
        this.hrefss = res.data;
      });
    },
    // 取消登录
    loginout() {
      this.$Http.getlogoutUrl().then((res) => {
        // console.log(res, "退出登录");
        this.outhrefss = res.data;
      });
    },
    // ----进入后台管理
    doOther() {
      // this.$message.success("正在进入管理后台...");
      this.$router.push({
        path: "/backsystem",
      });
    },
    // -----全局搜索
    queryFile() {
      console.log("查找");
    },

    //-------路由跳转-----------
    async handleSelect(item, id) {
      // console.log(item, id, "导航", this.$route.name);
      if (this.$route.meta.curId == item) return;
      if (item == 9 && this.username == null) {
        await this.loginsys();
        window.location.href = this.hrefss; // window.location(this.hrefss);
      }
      // this.nowId = id;
      this.$router.push({
        name: `${item}`,
      });
    },
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        // console.log(val, oldVal, "index路由");
        this.$nextTick(() => {
          this.$refs.ddd.scrollTo(0, 0);
        });
        if (val.query.paths == "通知公告") {
          this.nowId = null;
        } else {
          this.nowId = val.meta.curId;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  ::v-deep .el-dropdown-menu__item {
    line-height: 20px;
  }
}
</style>

<style scoped lang="scss">
.ziyun {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background: #f3f4f7;
  header {
    position: fixed;
    width: 100%;
    height: 124px;
    z-index: 100;
    background-color: #fff;
    .h_center {
      width: 1200px;
      margin: 0 auto;
      padding: 25px 0;
      display: flex;
      justify-content: space-between;
      .h_logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        img {
          width: 74px;
          height: 74px;
        }
        .logo_title {
          margin: -5px 0 0 10px;
          .logo_one {
            //width: 192px;
            height: 53px;
            font-size: 30px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            line-height: 53px;
            letter-spacing: 2px;
          }
          .logo_two {
            width: 167px;
            height: 26px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            line-height: 26px;
            text-align: center;
          }
        }
        .logo_subtitle {
          p{
            line-height: 20px;
          }
          font-size: 14px;
          margin: 0 0 0 10px;
        }
      }
      .h_withe {
        width: 100px;
        height: 100%;
      }
      .el-input {
        margin-top: 15px;
        width: 300px;
        ::v-deep .el-input__inner {
          border-radius: 18px;
        }
        .el-input__icon {
          height: 0;
          cursor: pointer;
        }
      }
      .h_login {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .user_img {
          width: 48px;
          margin-top: -8px;
        }
        .loginicon {
          // cursor: pointer;
          margin-left: 10px;
          // width: 108px;
          height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 600;
          color: #141516;
          line-height: 21px;
          span {
            display: inline-block;
            height: 24px;
            line-height: 28px;
            // vertical-align: middle;
          }
          a {
            margin-left: 10px;
            vertical-align: text-top;
            // i{
            //   display: inline-block;
            //   width: 21px;
            //   height: 21px;
            //   background: url('../assets/icon/out.png')no-repeat;
            //   vertical-align: middle;
            // }
          }
        }
        .badge {
          // width: 20px;
          margin-left: 15px;

          i {
            display: inline-block;
            width: 25px;
            height: 25px;
            line-height: 20px;
            color: #1977f9;
            background: url("../assets/index/email.png");
          }
        }

        a {
          text-decoration: none;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #141516;
        }
        a:hover {
          color: #1977f9;
        }
        .el-dropdown {
          height: 20px;
          margin-left: 5px;
          span {
            display: inline-block;
            margin-left: 5px;
            // width: 48px;
            height: 24px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #1977f9;
            line-height: 21px;
            cursor: pointer;
          }
        }
        .login_other {
          cursor: pointer;
          margin-left: 42px;
          width: 108px;
          height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 600;
          color: #141516;
          line-height: 28px;
        }
        .login_other:hover {
          color: #1977f9;
        }
      }
    }
  }
  nav {
    // position: fixed;
    // width: 1920px;
    width: 100%;
    margin-top: 124px;
    background: #1977f9;

    .el-menu-demo {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border: 0;
      .el-menu-item {
        border: 0;
      }
      ::v-deep .is-active {
        border: 0;
        background-color: rgb(20, 95, 199) !important;
      }
    }
  }
  main {
    min-height: 745px;
  }
  footer {
    margin-top: 15px;
    background: #01284f;
    // margin-top: 60px;
    .footer_content {
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      opacity: 1;
    }
  }
}
</style>
